<script lang="ts">
	export let title: string;
	export let icon: string;
</script>

<section>
	<span class="icon solid major fa-{icon}" />
	<h3>{title}</h3>
	<p>
		<slot />
	</p>
</section>

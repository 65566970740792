import { FormFieldError } from './forms';

export type FormParams = {
	name: string;
	email: string;
	message: string;
};

export enum ResponseStatus {
	Success = 'Success',
	Error = 'Error',
	ValidationError = 'ValidationError'
}

export type ContactResponseBody = {
	status: ResponseStatus;
	errors?: FormValidation;
};

export class FormValidation {
	name?: FormFieldError;
	email?: FormFieldError;
	message?: FormFieldError;

	valid(): boolean {
		for (let field in this) {
			if (this[field] !== undefined) {
				return false;
			}
		}

		return true;
	}
}

const emailPattern: RegExp =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function validateForm(form: FormParams): FormValidation {
	let errors = new FormValidation();

	for (let field in form) {
		const fieldIndex = field as keyof FormParams;
		if ((form[fieldIndex] as string).trim() === '') {
			errors[fieldIndex] = FormFieldError.EmptyField;
		}
	}

	if (errors.email === undefined && !emailPattern.test(form.email)) {
		errors.email = FormFieldError.InvalidInput;
	}

	return errors;
}

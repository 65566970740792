<script lang="ts">
	import Button from '$lib/components/Button.svelte';
	import ContactForm from '$lib/components/ContactForm.svelte';
	import Navbar from '$lib/components/Navbar.svelte';
	import Service from '$lib/components/Service.svelte';
</script>

<Navbar />

<!-- Wrapper -->
<div id="wrapper">
	<!-- Intro -->
	<section id="intro" class="wrapper style0 fullscreen fade-up">
		<span class="image fit">
			<img
				src="images/theoretically_inc_logo_new.webp"
				alt=" Theoretically Incorporated"
				title="Theoretically Incorporated"
			/>
		</span>
	</section>

	<!-- What I Offer -->
	<section id="what-i-offer" class="wrapper style3 fade-up">
		<div class="inner">
			<h1>What Theoretically Offers</h1>
			<p>
				Theoretically Incorporated offers technology consulting and development services to
				individuals and small businesses in the triangle. Theoretically provides full custom
				solutions including websites, mobile apps, custom software or anything else you may need to
				take advantage of modern technology in your business.
			</p>
			<p>
				It is easy to get lost as a small business when working with larger technology firms.
				Theoretically works closely with you and your budget to make sure you get the attention to
				detail that you deserve.
			</p>
			<div class="features">
				<Service icon="code" title="Web Development">
					Using industry standards and best practices, I build modern websites and webapps. I have
					the experience to scale your project to almost any size.
				</Service>
				<Service icon="search" title="Search Engine Optimization">
					I can help improve your search engine performance using a variety of tools. Make your
					business more visible without having to work with large and expensive marketing or SEO
					firms.
				</Service>
				<Service icon="mobile-alt" title="Mobile App Development">
					Mobile apps can help you have a better relationship with your customers. They can drive
					more engagement and make things easier for customers and you. This is achieveable on a
					budget. There is no need to pay upwards of $30,000 for a basic app. Reach out to see how I
					can help!
				</Service>
				<Service icon="desktop" title="Software Development">
					I can create custom software to solve your business challenges or improve your workflow.
					Automate manual processes to increase productivity and reduce cost and overhead.
				</Service>
				<Service icon="users" title="Technology Consulting">
					In addition to technology development, I can help you find existing solutions and
					implement them in your oganization.
				</Service>
				<Service icon="lock" title="Digital Security Consulting">
					Security is one of my passions. If you need help adding or implementing additional
					security measures in your business or project, I am uniquely qualified to help.
				</Service>
			</div>
		</div>
	</section>

	<!-- About Me -->
	<section id="about-me" class="wrapper style2">
		<div class="inner">
			<h1>About Me</h1>
			<div class="spotlights">
				<section>
					<div class="image">
						<img src="images/ryan_profile.webp" alt="Ryan Sullivan" data-position="center center" />
					</div>
					<div class="content">
						<div class="inner">
							<h2 class="bio-name">Ryan Sullivan</h2>
							<h3 class="bio-job-title">Founder and CEO</h3>
							<p>
								I graduated from Marist College with a Bachelors in Computer Science and a Minor in
								Information Technology in 2012. I then went on to earn a Masters in Software
								Development in 2013. Over the past 10 years I have worked in a variety of roles
								including mainframe testing, development of email security services, website
								development, and mobile app development.
							</p>
							<ul class="actions">
								<li>
									<Button href="https://www.linkedin.com/in/ryan-sullivan-developer">
										View LinkedIn
									</Button>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</div>
	</section>

	<!-- Get In Touch -->
	<section id="get-in-touch" class="wrapper style1 fade-up">
		<div class="inner">
			<h1>Get in Touch</h1>
			<p>
				If you would like to get a quote or just have a chat to see if I can help, feel free to fill
				in the form below, email, or call.
			</p>
			<div class="split style1">
				<section>
					<ContactForm />
				</section>
				<section>
					<ul class="contact">
						<li>
							<h3>Email</h3>
							<a href="mailto:ryan@theoretically.co">ryan@theoretically.co</a>
						</li>
						<li>
							<h3>Phone</h3>
							<a href="tel:5165378559">(516) 537-8559</a>
						</li>
					</ul>
				</section>
			</div>
		</div>
	</section>
</div>

<style lang="scss">
	h2.bio-name {
		margin-bottom: 0em;
	}

	h3.bio-job-title {
		margin-bottom: 0.5em;
	}

	.spotlights .image img {
		widows: 100%;
	}
</style>

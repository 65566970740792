export enum FormFieldError {
	EmptyField,
	InvalidInput
}

export function errorMessageForField(fieldName: string, error?: FormFieldError): string {
	switch (error) {
		case FormFieldError.EmptyField:
			return `Please enter your ${fieldName}`;

		case FormFieldError.InvalidInput:
			return `This is not a valid ${fieldName}`;

		default:
			return '';
	}
}

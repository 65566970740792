<script lang="ts" context="module">
	declare const jQuery: any;
</script>

<script lang="ts">
	import { onMount } from 'svelte';
	import { initNav } from '$lib/template';

	onMount(() => {
		initNav(jQuery);
	});
</script>

<!-- Sidebar -->
<section id="sidebar">
	<div class="inner">
		<nav>
			<ul>
				<li><a href="#intro">Theoretically Inc.</a></li>
				<li><a href="#what-i-offer">What I Offer</a></li>
				<li><a href="#about-me">About Me</a></li>
				<li><a href="#get-in-touch">Get in Touch</a></li>
			</ul>
		</nav>
	</div>
</section>

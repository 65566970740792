<script lang="ts">
	import { applyAction, enhance } from '$app/forms';
	import { page } from '$app/stores';
	import { ResponseStatus, type ContactResponseBody } from '$lib/contact';
	import { errorMessageForField } from '$lib/forms';

	const ERROR_STATUS = [ResponseStatus.Error, ResponseStatus.ValidationError];

	let formInFlight = false;

	function messageForForm(status: ResponseStatus): string {
		switch (status) {
			case ResponseStatus.Success:
				return 'Message sent! I will get back to you soon.';

			case ResponseStatus.Error:
				return 'There was a server error. Please try again later.';

			default:
				return '';
		}
	}
</script>

<form
	method="POST"
	action="/contact"
	use:enhance={() => {
		// Can do validation here and cancel submission if needed.
		formInFlight = true;

		return async ({ result, update }) => {
			formInFlight = false;
			await update();
			await applyAction(result);
		};
	}}
>
	<div class="fields">
		<div class="field half">
			<label for="name">Name</label>
			<input
				type="text"
				name="name"
				id="name"
				class:error={$page.form?.errors?.name !== undefined}
			/>
			<label for="name" class="field-message error">
				{errorMessageForField('name', $page.form?.errors?.name)}
			</label>
		</div>
		<div class="field half">
			<label for="email">Email</label>
			<input
				type="text"
				name="email"
				id="email"
				class:error={$page.form?.errors?.email !== undefined}
			/>
			<label for="email" class="field-message error">
				{errorMessageForField('email address', $page.form?.errors?.email)}
			</label>
		</div>
		<div class="field">
			<label for="message">Message</label>
			<textarea
				name="message"
				id="message"
				rows="5"
				class:error={$page.form?.errors?.message !== undefined}
			/>
			<label for="message" class="field-message error">
				{errorMessageForField('message', $page.form?.errors?.name)}
			</label>
		</div>
	</div>
	<ul class="actions">
		<li>
			<input type="submit" class="button" value="Send Message" />
		</li>
		<li class="progress-indicator" class:show={formInFlight}>
			<i class="fas fa-spinner fa-pulse" />
		</li>
		<li
			class="form-message"
			class:error={ERROR_STATUS.includes($page.form?.status)}
			class:success={!ERROR_STATUS.includes($page.form?.status)}
		>
			{messageForForm($page.form?.status)}
		</li>
	</ul>
</form>

<style lang="scss">
	@import '../scss/libs/vars';
	@import '../scss/libs/functions';

	.form-message {
		padding: 10px;
	}

	.field-message {
		padding: 5px;
		font-weight: normal;
		font-size: 14px;
	}

	input.error,
	textarea.error {
		border-color: _palette(error);
		color: _palette(fg);
	}

	.error {
		color: _palette(error);
	}

	.success {
		color: _palette(success);
	}

	.progress-indicator {
		padding: 10px;
		display: none;

		&.show {
			display: inline;
		}
	}
</style>
